
.container {
  background: rgb(12, 10, 23);

  height: 100vh;
  width: 100vw;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}
img{
    /* max-width: 50vw;
    max-height: 50vh;
    @media screen and (max-width: 650px) {
        max-width: 40vw;
    max-height: 40vh;
       } */     
       max-width: -webkit-fill-available;
       width: 100%;
}
h1 {
  margin: 0;
  font-size: clamp(24px, 4vw, 68px);
  color: white;

}
p {
  margin: 0;
  font-size: clamp(20px, 4vw, 44px);
  color: white;


}
span {
  color: rgb(66, 135, 245);
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;
  justify-content: center;
}
